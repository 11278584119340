.acesso {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  min-width: 520px;
  min-height: 700px;

  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.form {
  padding: 56px 48px 36px;
  width: 301px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .35);
  border-radius: 20px;
  text-align: center;
  font-size: 16px;
  background-color: #fff;

  img {
    margin: -10% auto 30px;
    max-width: 90%;
    max-height: 140px;
  }

  h2 {
    width: 100%;
    text-align: center;
    font-weight: normal;
    font-size: 22px;
    line-height: 42px;
    margin: 4px 0 36px;
  }

  button.MuiButton-contained {
    text-transform: uppercase;
    margin-top: 28px;
    height: 40px;
    line-height: 40px;
    width: 100%;
    border-radius: 30px;
    margin-bottom: 16px;
  }
}

@media (max-width: 750px) {
  .form {
    margin-top: 280px;
  }
}
