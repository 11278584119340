
html, body, #root {
  height: 100%;
  font-family: "Roboto", SansSerif, sans-serif;
}

body .MuiFormControl-root {
  width: 100%;
  margin-bottom: 30px;
}

body .MuiCard-root {
  box-shadow: none;
  border: none;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  margin-bottom: 30px;
  padding: 30px 80px 30px 30px;

  .acoes {
    margin-top: 60px;
    display: flex;
    flex-flow: row-reverse;
    gap: 20px;

    .spacer {
      flex: 1 1 auto;
    }
  }
}

body .MuiCard-root h2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: .15px;
  margin-bottom: 40px;
}

body .MuiMenuItem-root {
  min-height: 48px;
}

body .MuiCard-root {

  table {
    border-bottom: 1px solid #E0E0E0;
    width: 100%;

    th, td {
      height: 42px !important;

      &:not(:last-child) {
        padding-right: 5px;
      }

      &.text-center {
        text-align: center
      }

      &.text-right {
        text-align: right
      }

      &.text-left {
        text-align: left
      }
    }

    .empty-state {
      padding: 10px;
      text-align: center
    }

    tbody tr {
      transition: .5s
    }

    tbody tr:hover {
      background-color: rgba(0, 0, 0, .03)
    }

    tbody td[routerLink] {
      cursor: pointer
    }

    tr {
      color: #333;

      .td, .th {
        font-size: 12px;
      }

      th {
        padding-left: 10px;
        padding-right: 10px;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.25px;
        text-transform: uppercase;
      }

      td {
        padding: 0 10px;
        transition: 50ms;
      }

      th:not(:last-child), td:not(:last-child) {
        border-right: 1px solid #E0E0E0;
      }

      th {
        border-top: 2px solid black;
        border-bottom: 2px solid black;
      }

      &:hover td {
        background-color: black;
        color: #fff;
        border-color: black;

        svg {
          fill: #fff;
        }

        &[routerLink], &[click] {
          cursor: pointer;
        }
      }
    }

  }
}


body .MuiCard-root > h2:first-child {
  margin-top: 0;
}

body .MuiSvgIcon-root,
body .MuiIcon-root {
  vertical-align: middle;
}
