.otp {
  display: flex;
  justify-items: center;
  gap: 10px;
  max-width: 280px;
  margin: 0 auto;

  & > input {
    -webkit-transition: none;
    width: inherit;
    min-width: 0;
    height: 40px;
    text-align: center;
    flex: 1;

    color: #3F4254;
    background-color: #f0f2f8;
    border-radius: 10px;
    border: 2px solid #c0c0ce;

    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  }

  & > input:focus {
    outline: 0;
  }

  & > input:active,
  & > input:focus {
    background-color: #EBEDF3;
    border: 2px solid #b8c4ea;
    color: #3F4254;
  }

}
