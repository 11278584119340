.header {
  background-color: #fff;
  display: flex;
  padding: 0 80px;
  align-items: center;
  height: 80px;
  border-bottom: 5px solid black;

  a.logo img {
    height: 50px;
    transition: 200ms;
  }

  h2 {
    flex: 1;
    margin: auto 10px;
    color: rgba(0, 0, 0, .54);
  }

  .spacer {
    flex: 1 1 auto;
  }

  button {
    color: rgba(0, 0, 0, .35);
    margin: auto 15px;

    .MuiIcon {
      vertical-align: middle;
    }
  }
}

@media (max-width: 750px) {
  .header {
    padding: 0;
    flex-direction: column-reverse;
  }
}
