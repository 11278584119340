.preloader {
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  opacity: 1;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, .8);
  transition: 300ms;
}

.preloader:not(.preloader-component) {
  position: fixed;
  z-index: 99999;
}

.preloader.preloader-component {
  position: absolute;
  z-index: 99999;
}

.preloader .cssload-speeding-wheel {
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 15px);
  width: 30px;
  height: 30px;
  margin: 0 auto;
  border: 2px solid black;
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;


  animation: cssload-spin 425ms infinite linear;
  -o-animation: cssload-spin 425ms infinite linear;
  -ms-animation: cssload-spin 425ms infinite linear;
  -webkit-animation: cssload-spin 425ms infinite linear;
  -moz-animation: cssload-spin 425ms infinite linear;
}

@keyframes cssload-spin {
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes cssload-spin {
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes cssload-spin {
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes cssload-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes cssload-spin {
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.preloader-text {
  display: block;
  text-align: center;
  width: 100%;
  height: 30px;
  color: #2b2b2b;
  position: relative;
  top: calc(50% + 15px);
}
