
.footer {
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  font-size: 12px;
  height: 40px;
}
