
.top {
  background-color: rgba(0, 0, 0, .35);
  height: 100px;
  border-bottom: 1px solid rgba(0, 0, 0, .15);
}

.menu {
  display: flex;
  height: 100%;
  flex-flow: column nowrap;
  border-top: 1px solid rgba(255, 255, 255, .15);
  padding: 30px 8px 20px 40px;
  background-color: #fff;
  border-top-right-radius: 8px;
  margin-top: 25px;

  button {
    transition: 300ms;
    width: 100%;
    color: #333;
    text-align: left;
    height: 60px;
    padding: 0 15px;
    border-radius: 4px;
    margin: 0 0 8px;
    justify-content: start;

    .MuiIcon-root.left {
      margin-right: 10px;
      float: left;
      line-height: 40px;
      height: 40px;
      width: 40px;
    }

    .MuiIcon-root.right {
      margin-left: 10px;
      float: right;
      line-height: 40px;
      height: 40px;
      width: 40px;
    }

  }

  .spacer {
    flex: 1 1 auto;
  }
}

@media(max-width: 1200px) {
  .menu {
    padding-left: 8px;
  }
}

