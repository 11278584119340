
.content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  padding: 16px;

  form {
    display: block;
    width: 100%;
    max-width: 400px;
    margin: auto;
    text-align: center;

    & > p {
      color: rgba(0, 0, 0, .5);
      margin: 20px 0;
    }

    .commands {
      margin-top: 20px;
      text-align: center;

      button {
        margin: 10px auto;
        display: block;
        min-width: 200px;
        height: 45px;
        z-index: 0;
      }

    }
  }
}
