.opcoes-cliente .MuiButton-root {
  width: 200px;
  height: 150px;
  display: block;
  padding: 20px;
  position: relative;
  border: 1px solid rgba(0, 0, 0, .3);

  h3 {
    margin: 10px 0 0;
    font-size: 16px;
  }

  .info {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 16px;
    text-align: right;
    text-transform: initial;

    small {
      font-size: 8px;
      display: block;
    }


  }
}
