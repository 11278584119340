.opcoes-lote .MuiButton-root {
  width: 200px;
  display: block;
  background-color: #fff;
  padding: 20px;

  h3 {
    margin: 10px 0 0;
    font-size: 16px;
  }
}
