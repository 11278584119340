.tabsBase {
  margin-bottom: 20px;
  border-bottom: 0;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background: #fff;

  button {
    height: 60px;
  }
}
