.copy-tooltip {
  display: inline;

  &:hover {
    cursor: pointer;
  }

  .copy-icon {
    height: 12px;
    width: 12px;
    font-size: 12px;
    margin-left: 3px;
    vertical-align: middle;
  }
}
